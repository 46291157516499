import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { animateAdvantagesList } from '../../utils/page-animations/common'

const AdvantagesList = ({ animation }) => {
  const { t } = useTranslation(['home', 'common'])
  const advantages = [1, 2, 3, 4, 5]

  useEffect(() => {
    if (animation.isReady && animation.canStart) {
      animateAdvantagesList()
    }
  }, [animation])

  return (
    <div className="row tpl-advantages-list">
      <div className="col col-lg-4 col-xl-3 col-lg-offset-1 col-xl-offset-2">
        <p className="ft-default mb-5 tpl-advantages-list__title">
          {t('home:statements:advantages:title')}
        </p>
      </div>
      <div className="col col-lg-8 col-xl-7">
        <div className="mosaic mosaic-lg-2 mosaic--advantages">
          {advantages.map((item) => (
            <div className="advantage" key={item}>
              <p className="ft-tertiary c-alpha-5 mb-2">0{item}</p>
              <h6 className="ft-400 mb-2">
                {t(`home:statements:advantages:advantage_${item}:title`)}
              </h6>
              <p className="ft-default ft-300 c-alpha-6 mb-0">
                {t(`home:statements:advantages:advantage_${item}:desc`)}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default AdvantagesList
