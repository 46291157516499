import gsap from 'gsap'
import { easeOutQuad } from '../../utils/constants/eases'
import { gsapParams } from '../../utils/page-animations/common'

const selectors = {
  wrapper: '.copy-to-clipboard__notification',
  content: '.copy-to-clipboard__notification span',
}

const setClipboard = () => {
  gsap.set(selectors.wrapper, { scaleX: 0 })
  gsap.set(selectors.content, { opacity: 0 })
}

const showClipBoard = () => {
  const tl = gsap.timeline().pause()

  tl.to(selectors.wrapper, { scaleX: 1, duration: 0.3, ease: easeOutQuad })
  tl.to(selectors.content, { ...gsapParams.fadeIn, duration: 0.3 }, 0.3)

  return tl
}

const hideClipBoard = () => {
  const tl = gsap.timeline().pause()

  tl.to(
    selectors.content,
    { ...gsapParams.fadeOut, duration: 0.3, ease: easeOutQuad },
    0
  )
  tl.to(selectors.wrapper, { scaleX: 0, duration: 0.3, ease: easeOutQuad }, 0.3)

  return tl
}

export { setClipboard, showClipBoard, hideClipBoard }
