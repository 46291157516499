import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { iconCopyPaste } from '../../utils/icons'
import CopyToClipboard from '../CopyToClipboard/CopyToClipboard'
import { animateContactEntry } from '../../utils/page-animations/common'

const Contact = ({ className, animation }) => {
  const { t } = useTranslation(['common'])

  const contactEmail = useMemo(() => t('common:contact:email'), [t])

  useEffect(() => {
    if (animation.isReady && animation.canStart) {
      animateContactEntry({})
    }
  }, [animation])

  return (
    <div
      className={`contact f f-ai-center f-direction-column ${
        className && className.length > 0 ? className : ''
      }`}
    >
      <h6 className="contact__title c-gray-300 ft-400 mb-5 mb-md-6 col col-md-8 col-lg-6 col-xl-4 ft-center">
        {t('common:contact:title')}
      </h6>
      <a
        className="contact__button btn btn--small btn--dark mb-2 mb-md-3"
        href={`mailto:${contactEmail}`}
      >
        {t('common:contact:cta')}
      </a>
      <CopyToClipboard
        value={contactEmail}
        messageOnCopy={t('common:contact:email_copied')}
      >
        <span className="link link--primary mt-1 contact__span contact__link">
          {iconCopyPaste}
          <span>{t('common:contact:email_copy')}</span>
        </span>
      </CopyToClipboard>
    </div>
  )
}

export default Contact
