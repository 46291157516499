import React, { memo, useCallback, useMemo, useRef, useState } from 'react'
import useClickOutside from '../../utils/hooks/useClickOutside'
import { iconChevronDown, iconChevronUp } from '../../utils/icons'

const _SelectOption = ({ children, isActive, onClick, tabIndex }) => (
  <div
    className={`select__option ${isActive ? 'select__option--active' : ''}`}
    onClick={onClick}
    onKeyDown={onClick}
    role="button"
    tabIndex={tabIndex}
  >
    {children}
  </div>
)
export const SelectOption = memo(_SelectOption)

const _Select = ({ renderOption, defaultValue, options, onSelect }) => {
  const selectRef = useRef()
  const [selectedValue, setSelectedValue] = useState(defaultValue)
  const [isOpen, setIsOpen] = useState(false)

  const handleToggleOpen = useCallback(() => setIsOpen(!isOpen), [isOpen])
  const handleSelectValue = useCallback(
    (opt) => () => {
      setSelectedValue(opt.value)
      setIsOpen(false)
      if (onSelect) {
        onSelect(opt)
      }
    },
    [onSelect]
  )
  const selectedOption = useMemo(
    () => options.find((opt) => opt.value === selectedValue),
    [selectedValue, options]
  )

  useClickOutside(
    selectRef,
    () => {
      setIsOpen(false)
    },
    !isOpen
  )
  return (
    <div className="select ft-secondary" ref={selectRef}>
      <div
        className="select__label"
        onClick={handleToggleOpen}
        onKeyDown={handleToggleOpen}
        role="button"
        tabIndex={0}
      >
        <span>{selectedOption?.label}</span>
        <span className="select__icon">
          {isOpen ? iconChevronUp : iconChevronDown}
        </span>
      </div>
      {isOpen && (
        <div className="select__list">
          {options.map((opt, i) => {
            const isActive = opt.value === selectedValue
            const handleSelectEvent = handleSelectValue(opt)
            return renderOption ? (
              renderOption({
                option: opt,
                isActive,
                index: i,
                onSelect,
                handleSelectEvent,
              })
            ) : (
              <SelectOption
                isActive={isActive}
                onClick={handleSelectEvent}
                tabIndex={0}
              >
                {opt.label}
              </SelectOption>
            )
          })}
        </div>
      )}
    </div>
  )
}
const Select = memo(_Select)

Select.Option = SelectOption
export default Select
