import React, { useContext, useEffect, useMemo } from 'react'
import Seo from '../SEO'
import Header from '../../Header'
import Footer from '../../Footer'
import { HistoryContext } from '../../../utils/hooks/History'
import { RouteProvider } from '../../../utils/hooks/Route'
import { addTrailingSlash, isBrowser } from '../../../utils/helpers/utils'

const ClassicLayout = (props) => {
  const { seo, children, internationalRoute, uri, name, language } = props
  const { addRouteToHistory } = useContext(HistoryContext)

  const currentRoute = useMemo(() => {
    if (!props.uri || !isBrowser) {
      return '/'
    }

    const uri = props.uri
    uri.replace('/en/', '/')
    uri.replace('/en', '/')
    const params = window.location.search
    const pathName = window.location.pathname
    return `${addTrailingSlash(pathName)}${params.length ? params : ''}`
  }, [props.uri])

  useEffect(() => {
    addRouteToHistory({
      path: currentRoute,
      type: 'root',
      scrollView: 0,
      name,
    })
  }, [currentRoute, addRouteToHistory, name])

  return (
    <>
      <Seo
        seo={seo}
        language={language}
        internationalRoute={internationalRoute}
        uri={uri}
      />
      <RouteProvider name={name}>
        <div className="page">
          <Header />
          <main className={`page__content page--classic tpl-${name}`}>
            {children}
          </main>
          <Footer internationalRoute={internationalRoute} />
        </div>
      </RouteProvider>
    </>
  )
}

export default ClassicLayout
