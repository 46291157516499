import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import copy from 'copy-to-clipboard'
import { notificationTimeout } from '../../utils/constants/global'
import { iconCheckMark } from '../../utils/icons'
import { handleKeyDownEnter } from '../../utils/helpers/utils'
import { showClipBoard, hideClipBoard, setClipboard } from './animations'

const CopyToClipboard = ({ value, messageOnCopy, children }) => {
  const [showNotification, setShowNotification] = useState(false)
  const tl = useRef({ show: null, hide: null })

  const handleClickCopyToClipboard = useCallback(() => {
    copy(value)
    setShowNotification(true)
    setTimeout(() => setShowNotification(false), notificationTimeout)
  }, [value])

  useEffect(() => {
    tl.current.show && tl.current.show.pause()
    tl.current.hide && tl.current.hide.pause()

    tl.current.show = showClipBoard()
    tl.current.hide = hideClipBoard()
    if (showNotification) {
      return tl.current.show && tl.current.show.restart()
    }
    tl.current.hide.restart()

    const tlShow = tl.current.show
    const tlHide = tl.current.hide

    return () => {
      tlShow && tlShow.kill()
      tlHide && tlHide.kill()
    }
  }, [showNotification])

  useEffect(() => {
    setClipboard()
  }, [])

  return (
    <>
      <span
        role="button"
        onClick={handleClickCopyToClipboard}
        onKeyPress={handleKeyDownEnter(handleClickCopyToClipboard)}
        tabIndex={0}
      >
        {children}
      </span>
      {/* {showNotification && */}
      <div className="copy-to-clipboard__notification bg-gray-800 c-white ft-default-m-small f f-center">
        <span className="copy-to-clipboard__icon mr-2">{iconCheckMark}</span>
        <span>{messageOnCopy}</span>
      </div>
      {/* } */}
    </>
  )
}

export default memo(CopyToClipboard)
