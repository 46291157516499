import React from 'react'

const clientIconInPng = ['zenly', 'bugaboo', 'chanel']

const ClientIcon = ({ client }) => {
  return (
    <div
      className={`client-icon ${
        !clientIconInPng.includes(client) && 'client-icon--svg'
      }`}
    >
      <img
        src={`/images/${client}.${
          clientIconInPng.includes(client) ? 'png' : 'svg'
        }`}
        alt={`${client} logo`}
      />
    </div>
  )
}

export default ClientIcon
