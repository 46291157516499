import { useEffect, useState, useMemo, useContext, useRef } from 'react';
import { checkEntryPage } from '../helpers/utils';
import { AnimationStateContext } from './AnimationState';

const useAnimationState = ({ routeName }) => {
  const [canSetEntryAnimation, setCanSetEntryAnimation] = useState(false)
  const [_canPlayAnimation, _setCanPlayAnimation] = useState(false)

  const { entryPage, isReady, canPlayAnimation, isAnimationAllowed } = useContext(AnimationStateContext)
  const isEntryPage = useMemo(() => checkEntryPage(entryPage, routeName), [routeName, entryPage])

  const $hasSetEntry = useRef(false)
  const $hasRunEntryAnimation = useRef(false)

  useEffect(() => {
     if (!isAnimationAllowed || !isEntryPage || $hasSetEntry.current)  {
      return
    }
    setCanSetEntryAnimation(true)

    $hasRunEntryAnimation.current = false
    $hasSetEntry.current = true
  }, [isAnimationAllowed, isEntryPage])

  useEffect(() => {
    if (!canPlayAnimation || !isEntryPage || !canSetEntryAnimation) {
      return
    }

    _setCanPlayAnimation(true)
    $hasRunEntryAnimation.current = true
  }, [canPlayAnimation, isEntryPage, canSetEntryAnimation])

  return useMemo(() => ({
    canStart: canSetEntryAnimation,
    canPlay: _canPlayAnimation,
    isEntryPage,
    isReady,
  }), [isEntryPage, isReady, canSetEntryAnimation, _canPlayAnimation])
  
};

export default useAnimationState;
