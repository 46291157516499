import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import LocalizedTransitionLink from '../LocalizedTransitionLink'
import { iconArrowRight } from '../../utils/icons'
import { useTranslation } from 'react-i18next'
import * as Vivus from 'vivus'
import gsap from 'gsap'
import { breakpoints } from '../../utils/helpers/utils'

const AnimatedCard = ({
  className,
  item,
  svg,
  duration,
  url,
  animation = {
    isReady: false,
    canStart: false,
    canPlay: false,
  },
  animationParams = {
    start: '-20% 80%',
    delay: 0,
  },
}) => {
  const { t } = useTranslation(['home', 'common'])
  const animatedCard = useRef(null)
  const hasRunAnimation = useRef()
  const ctaRef = useRef(null)
  const [vivus, setVivus] = useState(null)

  const initVivus = useCallback(() => {
    const vivusAnim = new Vivus(`animated-svg_${item}`, {
      file: `/images/${svg}.svg`,
      duration: duration,
      type: 'oneByOne',
      start: 'manual',
      onReady: () => {
        setVivus(vivusAnim)
      },
    })

    animatedCard.current.addEventListener('mouseenter', () =>
      onMouseEnter(vivusAnim)
    )
    animatedCard.current.addEventListener('mouseleave', () =>
      onMouseLeave(vivusAnim)
    )
  }, [duration, item, svg])

  useEffect(() => {
    const ref = animatedCard.current
    if (animatedCard.current) {
      initVivus()
    }

    return () => {
      ref.removeEventListener('mouseenter', onMouseEnter)
      ref.removeEventListener('mouseleave', onMouseLeave)
    }
  }, [initVivus])

  const onMouseEnter = (vivusAnim) => {
    if (vivusAnim) {
      vivusAnim.reset()
      vivusAnim.play()
    }

    if (ctaRef.current) {
      ctaRef.current.classList.add('hover')
    }
  }

  const onMouseLeave = (vivusAnim) => {
    if (vivusAnim) {
      vivusAnim.stop()
    }
    if (ctaRef.current) {
      ctaRef.current.classList.remove('hover')
    }
  }

  //scrolltrigger animations
  useEffect(() => {
    if (hasRunAnimation.current || !animatedCard.current) {
      return
    }

    if (!animation.canStart && animation.isReady) {
      hasRunAnimation.current = true
      return
    }
  }, [animation.canStart, animation.isReady])

  useEffect(() => {
    const isDesktop = window.innerWidth > breakpoints.lg

    if (
      !animation.canPlay ||
      hasRunAnimation.current ||
      !animatedCard.current ||
      !vivus ||
      isDesktop
    ) {
      return
    }

    hasRunAnimation.current = true
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: animatedCard.current,
        start: '-20% 80%',
        delay: 0,
        once: true,
        onEnter: () => {
          if (vivus) {
            vivus.play()
          }
        },
      },
    })

    return () => {
      hasRunAnimation.current = false
      tl && tl.kill()
    }
  }, [animation, animationParams, vivus])

  return (
    <LocalizedTransitionLink
      to={url}
      className={`card-animated ${className}`}
      ref={animatedCard}
      role="button"
      tabIndex={0}
    >
      <h6 className="mb-2 ft-400">
        {t(`home:statements:services:${item}:title`)}
      </h6>

      <div className="ft-default ft-300 mb-5">
        {t(`home:statements:services:${item}:desc1`)}
        <span>
          <span
            dangerouslySetInnerHTML={{
              __html: t(`home:statements:services:${item}:focus`),
            }}
          ></span>
          <div
            id={`animated-svg_${item}`}
            className="card-animated__svg desktop-only"
          ></div>
        </span>
        {t(`home:statements:services:${item}:desc2`)}
      </div>

      <div className="btn btn--service" ref={ctaRef}>
        <div>{iconArrowRight}</div>
        <span>{t(`home:statements:services:${item}:cta`)}</span>
      </div>
    </LocalizedTransitionLink>
  )
}

export default memo(AnimatedCard)
