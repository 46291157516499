import React, { memo, useCallback, useContext, useMemo } from 'react'
import Select from '../Select'
import { capitalizeFirstLetter } from '../../utils/helpers/utils'
import LocalizedTransitionLink from '../LocalizedTransitionLink'
import { I18nextContext } from 'gatsby-plugin-react-i18next'

const SelectLanguage = ({ internationalRoute }) => {
  const { language, languages, defaultLanguage } = useContext(I18nextContext)
  const options = useMemo(
    () =>
      languages.map((code) => {
        const prefix = defaultLanguage === code ? '' : `/${code}`
        const path = internationalRoute?.langs[code] || '/'
        return {
          value: code,
          label: capitalizeFirstLetter(code),
          url: `${prefix}${path}`,
        }
      }),
    [languages, defaultLanguage, internationalRoute]
  )

  const handleRenderOption = useCallback(
    ({ option, isActive, onClick }) => (
      <LocalizedTransitionLink
        key={option.value}
        localized={false}
        to={option.url}
      >
        <Select.Option isActive={isActive} onClick={onClick} tabIndex={0}>
          {option.label}
        </Select.Option>
      </LocalizedTransitionLink>
    ),
    []
  )
  return (
    <Select
      renderOption={handleRenderOption}
      defaultValue={language}
      options={options}
    />
  )
}

export default memo(SelectLanguage)
