import React, { useEffect } from 'react'
import ClientIcon from '../ClientIcon'
import { routeNames, getRoutesByLang } from '../../utils/helpers/routes'
import { useTranslation } from 'react-i18next'
import { animateClientsEntry } from '../../utils/page-animations/common'

const ListTheyTrustUs = ({
  language,
  link = false,
  className = '',
  clients = [],
  animation,
  gridClass = 'col-lg-8 col-xl-6',
}) => {
  const localizedRoutes = getRoutesByLang(language)
  const { t } = useTranslation()

  useEffect(() => {
    if (animation.isReady && animation.canStart) {
      animateClientsEntry()
    }
  }, [animation])

  return (
    <section className={`container ${className}`}>
      <div className="row client-icons">
        <div className="col col-lg-4 col-xl-3 col-lg-offset-1 col-xl-offset-2 mb-4 client-icons__title">
          <p className="ft-default mb-0">{t('service:they_trust_us')}</p>
          {link && (
            <a
              href={localizedRoutes[routeNames.clients]}
              className="link link--primary link--arrow"
            >
              <span>{t('service:our_clients')}</span>
            </a>
          )}
        </div>
        <div className={`col ${gridClass}`}>
          <ul className="mosaic mosaic--they-trust-us">
            {clients.map((client, index) => {
              return (
                <li
                  className={`mb-1 ${
                    index === 0 && clients.length > 8
                      ? 'hide-desktop'
                      : index > 8
                      ? 'show-only-xl'
                      : ''
                  }`}
                  key={`${client}_${index}`}
                >
                  <ClientIcon client={client} />
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default ListTheyTrustUs
