import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  memo,
} from 'react'
import { useTranslation } from 'react-i18next'
import { getNavByLang } from '../../utils/helpers/routes'
import LocalizedTransitionLink from '../LocalizedTransitionLink'
import SourceLogo from '../SourceLogo'
import SocialMedias from '../SocialMedias'
import SelectLanguage from './SelectLanguage'
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import { companyEmail } from '../../utils/constants/global'

const Footer = ({ internationalRoute }) => {
  const { language } = useContext(I18nextContext)
  const [currentTime, setCurrentTime] = useState('12:45')
  const { t } = useTranslation(['common'])

  const getParisTime = useCallback(() => {
    const options = {
      timeZone: 'Europe/Paris',
      hour: 'numeric',
      minute: 'numeric',
    }
    const formatter = new Intl.DateTimeFormat([], options)
    setCurrentTime(formatter.format(new Date()))
  }, [])

  const nav = useMemo(() => getNavByLang(language), [language])

  useEffect(() => {
    const interval = setInterval(() => getParisTime(), 5000)
    return () => clearInterval(interval)
  }, [getParisTime])

  useEffect(() => {
    getParisTime()
  }, [getParisTime])

  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="footer__logo col col-xs-4 col-md-3 col-lg-4 mb-5 mb-md-0">
            <SourceLogo />
          </div>
          <div className="hide-desktop">
            <SocialMedias className="col col-xs-4 mb-5" />
          </div>
          <div className="col col-xs-4 col-md-3 col-lg-3">
            <ul className="nav-footer">
              {nav.map((page) => (
                <li className="ft-secondary mb-2" key={page.id}>
                  <LocalizedTransitionLink to={page.slug} partiallyActive>
                    {t(`common:nav:${page.id}`)}
                  </LocalizedTransitionLink>
                </li>
              ))}
            </ul>
          </div>
          <div className="col col-xs-4 col-md-3 col-lg-3">
            <div className="mb-2 c-white">Paris</div>
            <div className="footer__time ft-secondary mb-2">{currentTime}</div>
            <div className="footer__email ft-secondary">{companyEmail}</div>
          </div>
          <div className="col col-xs-4 col-md-2 col-lg-2 mt-2 mt-md-0 f f-ai-center">
            <SelectLanguage internationalRoute={internationalRoute} />
          </div>
          <div className="hide-mobile">
            <SocialMedias className="col col-md-1 col-lg-2 mb-5 " />
          </div>
        </div>
      </div>
    </footer>
  )
}

export default memo(Footer)
